import { useEffect, useRef } from "react";
import { HiChevronDown } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import Logo from '../assets/logo_flag.png'

type Menu = {title:string, linkTo?:string, submenus?:Menu[]} 
type HeaderDropDownPropsType = {
    title:string;
    items:Menu[];
};


const HeaderDropDown:React.FC<HeaderDropDownPropsType> = ({title, items}) => {
     
    return (
        <div className="hs-dropdown [--strategy:static] sm:[--strategy:fixed] [--adaptive:none] sm:[--trigger:hover] sm:py-4">
            <button type="button" className="flex items-center w-full text-white/[.9] hover:text-white hs-dropdown-open:underline underline-offset-4 font-medium">
                {title}
                <HiChevronDown
                    size={18}
                    className="ml-0.5 mt-0.5"
                />
            </button>

            <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 hidden z-10 bg-white sm:shadow-md rounded-lg p-2 dark:bg-gray-800 sm:dark:border dark:border-gray-700 dark:divide-gray-700 before:absolute top-full sm:border before:-top-5 before:left-0 before:w-full before:h-5">
                {items.map (item => 
                      item.linkTo?
                        (
                            <Link
                                className="navBarDropdownItem"
                                to={item?.linkTo}
                            >
                                {item.title}
                            </Link>
                        )
                        :
                        (
                            <div className="hs-dropdown relative [--strategy:static] sm:[--strategy:absolute] [--adaptive:none] sm:[--trigger:hover]">
                                <button type="button" className="flex justify-between w-full items-center text-sm text-gray-800 rounded-lg py-2 px-2 hover:bg-primary hover:text-white focus:ring-2 focus:ring-blue-500 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                                    {item.title}
                                    <svg className="sm:-rotate-90 flex-shrink-0 ms-2 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6" /></svg>
                                </button>

                                <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 hidden z-10 sm:mt-2 bg-gray-50 sm:shadow-md rounded-lg p-2 dark:bg-gray-800 sm:dark:border dark:border-gray-700 dark:divide-gray-700 after:absolute sm:border after:-start-5 after:top-0 after:h-full after:w-5 top-0 end-full xl:start-full !mx-[10px]">
                                    {item.submenus?.map(submenu => 
                                        <Link to={submenu.linkTo||'#'} className="navBarDropdownItem">
                                            {submenu.title}
                                        </Link>
                                        )}
                                </div>
                            </div>
                        )

                )}
            </div>
        </div>
    )
}


const Header = () => {
    const location = useLocation();
    
    const headerRef:React.LegacyRef<HTMLDivElement> = useRef(null);
    const toggleButtonRef:React.LegacyRef<HTMLButtonElement> = useRef(null);

    const handleDocumentClick = (event: MouseEvent) => {
        // Check if the click target is inside or outside the header component
        if (headerRef.current && !headerRef.current.contains(event.target as (Node | null))) {
            // If outside, hide the dropdown menu
            if(toggleButtonRef.current?.classList.contains('open')) {
                toggleButtonRef.current?.click();
            }
        }
    };

    useEffect(() => {
        if(toggleButtonRef.current?.classList.contains('open')) {
            toggleButtonRef.current?.click();
        }
    }, [location]);
    
    // Use a useEffect hook to add and remove the event listener when the component mounts and unmounts
    useEffect(() => {
        // Add the event listener to the document
        document.addEventListener('click', handleDocumentClick);

        // Return a cleanup function that removes the event listener from the document
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);
    
    return (
        <header
            ref={headerRef}
            // Sets an image as background, and make it to fit/stretch to the container dimensions
            style={{backgroundImage: `url(${require('../assets/header_background.png')})`, backgroundSize:'100% 100%', height:'8rem'}}
            className="max-w-[85rem] mx-auto flex flex-wrap sm:justify-start sm:flex-nowrap text-sm w-full py-3 sm:py-0"
        >
            <nav className="relative z-10 max-w-[85rem] w-full mx-auto sm:flex sm:justify-between sm:px-6 lg:px-8">
                <div
                    className="flex items-center justify-between self-start mt-2 py-0 lg:py-3 rounded w-full lg:w-auto px-3"
                    style={{boxShadow:''}}
                >
                    <a
                        className="flex-none text-xl font-semibold text-white"
                        href="/"
                        aria-label="Brand"
                    >
                        <img
                            src={Logo}
                            className="h-16 w-[4.5rem]"
                            alt=""
                        />
                    </a>
                    <div className="bg-white lg:bg-none mx-2 p-0.5 lg:mx-0 rounded-xl">
                        <p
                            style={{textShadow:'1px 0px 50px 15px rgba(255,255,255,0.75)'}}
                            className="text-black ml-3 xl:ml-6 font-medium "
                        >
                            Agir pour la transparence et la légalité dans la gestion des ressources naturelles
                        </p>
                    </div>
                    <div className="sm:hidden">
                        <button ref={toggleButtonRef} type="button" className="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-md border border-white/[.5] font-medium text-white/[.5] shadow-sm align-middle hover:bg-white/[.1] hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm" data-hs-collapse="#navbar-collapse-with-animation" aria-controls="navbar-collapse-with-animation" aria-label="Toggle navigation">
                            <svg className="hs-collapse-open:hidden w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                            </svg>
                            <svg className="hs-collapse-open:block hidden w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div id="navbar-collapse-with-animation" className="hs-collapse hidden  overflow-hidden  transition-all duration-300 basis-full grow sm:block w-full bg-primary sm:bg-transparent px-3 pb-2">
                    <div className="flex flex-col gap-y-4 gap-x-0 mt-6 sm:flex-row sm:items-center sm:justify-end sm:gap-y-0 sm:gap-x-4 xl:gap-x-4 sm:mt-0 sm:pl-0 flex-wrap">
                        {/* <a className="font-medium text-white sm:py-6" href="#" aria-current="page">Landing</a> */}
                        <Link
                            className="navBarItem"
                            to={'/'}
                        >
                                Accueil
                        </Link>
                        {/* <HeaderDropDown
                            title="Acceuil"
                            items={[
                                {title:'Nouvelles informations', linkTo:'#'},
                                {title:'Événements à venir', linkTo:'#'}
                            ]}
                        /> */}
                        <HeaderDropDown
                            title="À propos"
                            items={[
                                {title:'Qui sommes-nous ?', linkTo:'/a-propos/qui-sommes-nous'},
                                {title: 'Valeurs', linkTo:'/a-propos/valeurs'},
                                // {title:'Missions', linkTo:'/a-propos/missions'},
                                {title:'Théorie de changement', linkTo:'/a-propos/theorie-de-changement'},
                                {title:"Domaines D'actions", submenus:[
                                    {title:'Accompagnement Communautaire', linkTo:'/das/accompagnement-communautaire'},
                                    {title:'Surveillance environnementale', linkTo:'/das/surveillance-environnementale'},
                                    {title:'Formation Continue (CEAS GRNE)', linkTo:'/das/formation-continue'},
                                    {title:"Qualité et Disponibilié de l'information", linkTo:'/das/qualite-et-disponibilite-de-linfo'},
                                    {title: 'Encadrement du Secteur Artisanal', linkTo:'/das/supervision-du-secteur-artisanal'},
                                ]},
                                {title:'Zones d’intervention', linkTo:'/a-propos/zones-dintervention'},
                                {title:'Organigramme', linkTo:'/a-propos/organigramme'},
                                {title:'Équipe', linkTo:'/a-propos/equipe'},
                                
                                // {title:'Les chroniques de FLAG', linkTo:'/a-propos/chroniques'}
                            ]}
                        />
                        {/* <HeaderDropDown
                            title="Nos Actions"
                            items={[
                                {title:'Surveillance environnementale', linkTo:'#'},
                                {title: 'Encadrement du secteur artisanal', linkTo:'#'},
                                {title:'Qualité et disponibilité de l’information', linkTo:'#'},
                                {title:'Accompagnement communautaire', linkTo:'#'},
                                {title:'Formation continue ou CEAS-GRNE', linkTo:'#'}
                            ]}
                        /> */}
                        {/* <HeaderDropDown
                            title="Programmes"
                            items={[
                                {title:'Légalité', linkTo:'/programme-legalite'},
                                {title: 'Transparence', linkTo:'/programme-transparence'},
                                {title:'Renforcement des capacités', linkTo:'/programme-renforcement-des-capacites'},
                                {title:'Développement organisationnel', linkTo:'/programme-developpement-organisationnel'},
                            ]}
                        /> */}
                        <HeaderDropDown
                            title="Nos Actions"
                            items={[
                                {title:'OTP-OI Cameroun', linkTo:'/nos-actions/otp-oi-cameroun'},
                                // {title:'NICFI', linkTo:'/nos-actions/nicfi'},
                                {title:'NORAD', linkTo:'/nos-actions/norad'},
                                {title:'RALFF', linkTo:'/nos-actions/ralff'},
                                {title:"IMWILD", linkTo:'/nos-actions/imwild'},
                                {title: 'USINL', linkTo:'/nos-actions/usinl'},
                            ]}
                        />
                        <HeaderDropDown
                            title="FLAG Lex"
                            items={[
                                {title:'Agriculture', linkTo:'/lex?group=agriculture'},
                                {title: 'Forêts', linkTo:'/lex?group=forets'},
                                {title:'Mines', linkTo:'/lex?group=mines'},
                                {title:'Faune', linkTo:'/lex?group=faune'},
                            ]}
                        />
                        <HeaderDropDown
                            title="Médiathèque"
                            items={[
                                {title:'Vidéos', linkTo:'/mediatheque/videos'},
                                {title:'Photos', linkTo:'/mediatheque/photos'},
                                {title: 'Publications', linkTo:'/mediatheque/documents'},
                                {title:'Pressbook', linkTo:'/mediatheque/pressbook'},
                                {title:'Blog (Forum)', linkTo:'/forums'},
                            ]}
                        />
                        
                        <Link
                            className="navBarItem"
                            to={'/contact'}
                        >
                            Nous Écrire
                        </Link>
                        <Link
                            target="_blank"
                            className="navBarItem"
                            to={'https://o2tri.com/'}
                        >
                            O2I-TRI
                        </Link>
                        <Link
                            target="_blank"
                            className="navBarItem text-sm"
                            to={'https://www.ceas-grne.com/'}
                        >
                            CEAS-GRNE
                        </Link>
                        {/* <a className="flex items-center gap-x-2 font-medium text-white/[.8] hover:text-white sm:border-l sm:border-white/[.3] sm:my-6 sm:pl-6" href="#">
                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                            </svg>
                            Log in
                        </a> */}
                    </div>
                </div>
            </nav>
        </header>
    )
};


export default Header;